@font-face {
    font-family: "Avenir";
    src: url("AvenirLTStd-Light.otf");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Avenir";
    src: url("AvenirLTStd-Book.otf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Avenir";
    src: url("AvenirLTStd-Medium.otf");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Avenir";
    src: url("AvenirLTStd-Black.otf");
    font-weight: 700;
    font-style: normal;
}

